import ReactPixel from "./facebook-pixel"
import Cookies from "js-cookie"

class MarketingService {
    constructor() {
        this.marketingInstance = null
        this.isInitialized = false
    }

    initialize() {
        if (Cookies.get("allowMarketingCookies") === "true" && typeof window !== "undefined") {
            this.marketingInstance = ReactPixel
            this.marketingInstance.init("698877930802595")
            this.isInitialized = true
        }
    }

    trackPage(path) {
        if (this.isInitialized) {
            this.marketingInstance.pageView()
        }
        return true
    }

    trackEvent(event, data) {
        if (this.isInitialized) {
            this.marketingInstance.track(event, data)
        }
        return true
    }
}

export default MarketingService